<template>
	
	
	
	
	
	<div class="goods-recommend" v-loading="loading" style="border: 0px solid #eeeeee;">
	<section data-v-7508d594="" class="trending__section pt-0">
		<div data-v-7508d594="" class="container">
			<div data-v-7508d594="" class="row">
				<div data-v-7508d594="" class="col-12">
					<div data-v-7508d594="" class="section-title">
						<h3 data-v-7508d594="" style="font-size: 25px;">สินค้าที่คุณอาจชอบ</h3></div>
				</div>
			</div>
			<div data-v-7508d594="" class="row" style="
	    width: 110%;
	">
				<div data-v-7508d594="" class="col-12">
					<div data-v-7508d594="" class="product__wrapper swiper__pagination">
						<div data-v-7508d594="" class="swiper trending__Swiper swiper-initialized swiper-horizontal swiper-pointer-events">
							<div data-v-7508d594="" id="swiper-wrapper-bfebd6f5cd4a759e" aria-live="polite" class="swiper-wrapper">
								<div v-for="(item, index) in list" :key="index"     class="swiper-slide swiper-slide-duplicate" style="width: 234px; margin-right: 30px;">
									<div data-v-7508d594="" class="product-card">
										<div data-v-7508d594="" class="product__image__wrapper">
											<a class="product__image" @click="$router.pushToTab({ path: '/sku-' + item.sku_id })" ><img data-v-7508d594="" :src="$img(item.sku_image
, { size: 'mid' })" @error="imageError(index)"  alt="icon"></a>
											<div data-v-7508d594="" class="badge">-{{ ((1-(item.discount_price/item.market_price))*100).toFixed(0) }}%</div>
											<div data-v-7508d594="" class="product__actions">
												<a data-v-7508d594="" href="#" class="action__btn">
													<svg data-v-7508d594="" width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
														<path data-v-7508d594="" d="M13.8931 2.07333C13.5526 1.73267 13.1483 1.46243 12.7033 1.27805C12.2584 1.09368 11.7814 0.998779 11.2998 0.998779C10.8181 0.998779 10.3412 1.09368 9.89618 1.27805C9.45121 1.46243 9.04692 1.73267 8.70642 2.07333L7.99975 2.78L7.29309 2.07333C6.60529 1.38553 5.67244 0.999136 4.69975 0.999136C3.72706 0.999136 2.79422 1.38553 2.10642 2.07333C1.41863 2.76112 1.03223 3.69397 1.03223 4.66666C1.03223 5.63935 1.41863 6.5722 2.10642 7.26L2.81309 7.96666L7.99975 13.1533L13.1864 7.96666L13.8931 7.26C14.2337 6.91949 14.504 6.51521 14.6884 6.07023C14.8727 5.62526 14.9676 5.14832 14.9676 4.66666C14.9676 4.185 14.8727 3.70807 14.6884 3.26309C14.504 2.81812 14.2337 2.41383 13.8931 2.07333V2.07333Z" stroke="#252522" stroke-linecap="round" stroke-linejoin="round"></path>
													</svg>
												</a>
												<a data-v-7508d594="" href="#" data-bs-toggle="modal" data-bs-target="#prod__preview" class="action__btn">
													<svg data-v-7508d594="" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
														<path data-v-7508d594="" d="M0.666992 7.99996C0.666992 7.99996 3.33366 2.66663 8.00033 2.66663C12.667 2.66663 15.3337 7.99996 15.3337 7.99996C15.3337 7.99996 12.667 13.3333 8.00033 13.3333C3.33366 13.3333 0.666992 7.99996 0.666992 7.99996Z" stroke="#252522" stroke-linecap="round" stroke-linejoin="round"></path>
														<path data-v-7508d594="" d="M8 10C9.10457 10 10 9.10457 10 8C10 6.89543 9.10457 6 8 6C6.89543 6 6 6.89543 6 8C6 9.10457 6.89543 10 8 10Z" stroke="#252522" stroke-linecap="round" stroke-linejoin="round"></path>
													</svg>
												</a>
												<a data-v-7508d594="" href="#" class="action__btn">
													<svg data-v-7508d594="" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
														<path data-v-7508d594="" d="M10 9.33329L13.3333 5.99996L10 2.66663" stroke="#252522" stroke-linecap="round" stroke-linejoin="round"></path>
														<path data-v-7508d594="" d="M2.66699 13.3333V8.66667C2.66699 7.95942 2.94794 7.28115 3.44804 6.78105C3.94814 6.28095 4.62641 6 5.33366 6H13.3337" stroke="#252522" stroke-linecap="round" stroke-linejoin="round"></path>
													</svg>
												</a>
											</div>
										</div>
										<div data-v-7508d594="" class="product__content">
											<div data-v-7508d594="" class="product__title">
												<h5 data-v-7508d594=""><a @click="$router.pushToTab({ path: '/sku-' + item.sku_id })" style="    font-weight: 500;" >{{ item.goods_name }}</a></h5></div>
											<div data-v-7508d594="" class="product__bottom">
												<div data-v-7508d594="" class="product__price"> ฿{{ item.discount_price }} <del data-v-7508d594="">฿{{ item.market_price
 }}</del></div>
												<div data-v-7508d594="" class="cart__action__btn">
													<div data-v-7508d594="" class="cart__btn"><a @click="addToCart(item)" class="btn btn-outline" style="    width: 83px;
    height: 38px;
    font-size: 13px;
    border: 2px solid #ffacac; font-family: 'Prompt';
    font-weight: 600; ">ใส่ตะกร้า</a></div>
													<div data-v-7508d594="" class="quantity cart__quantity">
														<button data-v-7508d594="" type="button" class="decressQnt"><span data-v-7508d594="" class="bar"></span></button>
														<input data-v-7508d594="" type="number" disabled="disabled" value="0" min="01" max="100" class="qnttinput">
														<button data-v-7508d594="" type="button" class="incressQnt"><span data-v-7508d594="" class="bar"></span></button>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
		<ul v-if="list.length" style="display: none;">
			<li v-for="(item, index) in list" :key="index"  @click="$router.pushToTab({ path: '/sku-' + item.sku_id })">
				<div class="img-wrap"><img :src="$img(item['sku_image'], { size: 'mid' })" @error="imageError(index)" /></div>
				<div class="price">฿{{ item.discount_price }}</div>
				<p class="sku-name">{{ item.goods_name }}</p>
				<div class="info-wrap"></div>
			</li>
		</ul>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import { goodsRecommend } from '../api/goods/goods';
export default {
	name: 'goods_recommend',
	props: {
		page: {
			type: [Number, String],
			default: 1
		},
		pageSize: {
			type: [Number, String],
			default: 20
		}
	},
	data: () => {
		return {
			loading: true,
			list: []
		};
	},
	created() {
		this.getGoodsRecommend();
	},
	computed: {
		...mapGetters(['defaultGoodsImage'])
	},
	methods: {
		getGoodsRecommend() {
			goodsRecommend({
				page: this.page,
				page_size: this.pageSize
			})
				.then(res => {
					if (res.code == 0) this.list = res.data.list;
					this.loading = false;
				})
				.catch(res => {
					this.loading = false;
				});
		},
		addToCart(item) {
		    this.$store
		        .dispatch('cart/add_to_cart', item)
		        .then(res => {
		            var data = res.data
		            if (data > 0) {
		                this.$notify({
		                   title: 'เรียบร้อยแล้ว',
		                    message: "หยิบใส่ตะกร้าสําเร็จ",
		                    title: 'เรียบร้อยแล้ว',type: "success"
		                })
		            }
		        })
		        .catch(err => err);
		},
		imageError(index) {
			this.list[index].sku_image = this.defaultGoodsImage;
		}
	}
};
</script>

<style lang="scss" scoped>
.goods-recommend {
	border: 1px solid #eeeeee;
	h4 {
		margin: 10px;
	}
	ul {
		li {
			padding: 10px;
			cursor: pointer;
			.price {
				color: ฿base-color;
				font-size: 16px;
			}
			.sku-name {
				font-size: ฿ns-font-size-sm;
				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
			}
			&:hover {
				color: ฿base-color;
			}
		}
	}
}
.fa-regular,
.fa-solid {
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	display: var(--fa-display, inline-block);
	font-style: normal;
	font-variant: normal;
	line-height: 1;
	text-rendering: auto;
}

.fa-star:before {
	content: "\f005";
}

.fa-regular {
	font-family: "Font Awesome 6 Free";
	font-weight: 400;
}

.fa-solid {
	font-family: "Font Awesome 6 Free";
	font-weight: 900;
}


/*! CSS Used from: https://demo.themeies.com/supermart-html/assets/css/bootstrap.min.css */

*,
::after,
::before {
	box-sizing: border-box;
}

h2,
h5 {
	margin-top: 0;
	margin-bottom: .5rem;
	font-weight: 500;
	line-height: 1.2;
}

h2 {
	font-size: calc(1.325rem + .9vw);
}

@media (min-width:1200px) {
	h2 {
		font-size: 2rem;
	}
}

h5 {
	font-size: 1.25rem;
}

ul {
	padding-left: 2rem;
}

ul {
	margin-top: 0;
	margin-bottom: 1rem;
}

a {
	color: #0d6efd;
	text-decoration: underline;
}

a:hover {
	color: #0a58ca;
}

img,
svg {
	vertical-align: middle;
}

button {
	border-radius: 0;
}

button:focus:not(:focus-visible) {
	outline: 0;
}

button,
input {
	margin: 0;
	font-family: inherit;
	font-size: inherit;
	line-height: inherit;
}

button {
	text-transform: none;
}

[role=button] {
	cursor: pointer;
}

[type=button],
button {
	-webkit-appearance: button;
}

::-moz-focus-inner {
	padding: 0;
	border-style: none;
}

.container {
	width: 100%;
	padding-right: var(--bs-gutter-x, .75rem);
	padding-left: var(--bs-gutter-x, .75rem);
	margin-right: auto;
	margin-left: auto;
}

@media (min-width:576px) {
	.container {
		max-width: 540px;
	}
}

@media (min-width:768px) {
	.container {
		max-width: 720px;
	}
}

@media (min-width:992px) {
	.container {
		max-width: 960px;
	}
}

@media (min-width:1200px) {
	.container {
		max-width: 1140px;
	}
}

@media (min-width:1400px) {
	.container {
		max-width: 1320px;
	}
}

.row {
	--bs-gutter-x: 1.5rem;
	--bs-gutter-y: 0;
	display: flex;
	flex-wrap: wrap;
	margin-top: calc(var(--bs-gutter-y) * -1);
	margin-right: calc(var(--bs-gutter-x) * -.5);
	margin-left: calc(var(--bs-gutter-x) * -.5);
}

.row>* {
	flex-shrink: 0;
	width: 100%;
	max-width: 100%;
	padding-right: calc(var(--bs-gutter-x) * .5);
	padding-left: calc(var(--bs-gutter-x) * .5);
	margin-top: var(--bs-gutter-y);
}

.col-12 {
	flex: 0 0 auto;
	width: 100%;
}

.btn {
	display: inline-block;
	font-weight: 400;
	line-height: 1.5;
	color: #212529;
	text-align: center;
	text-decoration: none;
	vertical-align: middle;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	user-select: none;
	background-color: transparent;
	border: 1px solid transparent;
	padding: .375rem .75rem;
	font-size: 1rem;
	border-radius: .25rem;
	transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion:reduce) {
	.btn {
		transition: none;
	}
}

.btn:hover {
	color: #212529;
}

.btn:focus {
	outline: 0;
	box-shadow: 0 0 0 .25rem rgba(13, 110, 253, .25);
}

.btn:disabled {
	pointer-events: none;
	opacity: .65;
}

.badge {
	display: inline-block;
	padding: .35em .65em;
	font-size: .75em;
	font-weight: 700;
	line-height: 1;
	color: #fff;
	text-align: center;
	white-space: nowrap;
	vertical-align: baseline;
	border-radius: .25rem;
}

.badge:empty {
	display: none;
}

.pt-0 {
	padding-top: 0!important;
}


/*! CSS Used from: https://demo.themeies.com/supermart-html/assets/css/swiper-bundle.min.css */

.swiper {
	margin-left: auto;
	margin-right: auto;
	position: relative;
	overflow: hidden;
	list-style: none;
	padding: 0;
	z-index: 1;
}

.swiper-wrapper {
	position: relative;
	width: 100%;
	height: 100%;
	z-index: 1;
	display: flex;
	transition-property: transform;
	box-sizing: content-box;
}

.swiper-wrapper {
	transform: translate3d(0px, 0, 0);
}

.swiper-pointer-events {
	touch-action: pan-y;
}

.swiper-slide {
	flex-shrink: 0;
	width: 100%;
	height: 100%;
	position: relative;
	transition-property: transform;
}

.swiper-button-next,
.swiper-button-prev {
	position: absolute;
	top: 50%;
	width: calc(var(--swiper-navigation-size)/ 44 * 27);
	height: var(--swiper-navigation-size);
	margin-top: calc(0px - (var(--swiper-navigation-size)/ 2));
	z-index: 10;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	color: var(--swiper-navigation-color, var(--swiper-theme-color));
}

.swiper-button-next:after,
.swiper-button-prev:after {
	font-family: swiper-icons;
	font-size: var(--swiper-navigation-size);
	text-transform: none!important;
	letter-spacing: 0;
	font-variant: initial;
	line-height: 1;
}

.swiper-button-prev {
	left: 10px;
	right: auto;
}

.swiper-button-prev:after {
	content: 'prev';
}

.swiper-button-next {
	right: 10px;
	left: auto;
}

.swiper-button-next:after {
	content: 'next';
}

.swiper-pagination {
	position: absolute;
	text-align: center;
	transition: .3s opacity;
	transform: translate3d(0, 0, 0);
	z-index: 10;
}

.swiper-pagination-progressbar {
	background: rgba(0, 0, 0, .25);
	position: absolute;
}

.swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
	background: var(--swiper-pagination-color, var(--swiper-theme-color));
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	transform: scale(0);
	transform-origin: left top;
}

.swiper-horizontal>.swiper-pagination-progressbar,
.swiper-pagination-progressbar.swiper-pagination-horizontal {
	width: 100%;
	height: 4px;
	left: 0;
	top: 0;
}

.swiper .swiper-notification {
	position: absolute;
	left: 0;
	top: 0;
	pointer-events: none;
	opacity: 0;
	z-index: -1000;
}


/*! CSS Used from: https://demo.themeies.com/supermart-html/assets/css/style.css */

* {
	margin: 0;
	padding: 0;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}

a {
	text-decoration: none;
}

a:hover,
a:focus {
	text-decoration: none;
	outline: 0px;
}

button:hover,
button:focus {
	outline: 0px;
}

h2,
h5 {
	font-weight: 400;
}

img {
	display: block;
	width: 100%;
	height: auto;
}

ul {
	margin: 0;
	padding: 0;
	list-style: inherit;
}

section {
	display: block;
	position: relative;
	padding: 60px 0;
	width: 100%;
}

@media screen and (max-width: 479px) {
	section {
		padding: 50px 0;
	}
}

input[type="number"] {
	-moz-appearance: textfield!important;
}

::-webkit-scrollbar {
	width: 4px;
	height: 4px;
}



::-webkit-scrollbar-thumb {
	background-color: #a7a7a7;
	border-radius: 10px;
}

button:hover,
button:focus {
	outline: 0px;
}

::-moz-selection {
	background: #a7a7a7;
	color: #fff;
}

::selection {
	background: #a7a7a7;
	color: #fff;
}

.row > * {
	max-width: 100%;
	padding-right: calc(var(--bs-gutter-x) * 1);
	padding-left: calc(var(--bs-gutter-x) * 1);
	margin-top: var(--bs-gutter-y);
}

h2 {
	font-family: "Prompt", sans-serif;
	font-size: 36px;
	font-weight: 700;
	line-height: 44px;
	letter-spacing: -0.02em;
	color: #252522;
}

@media screen and (max-width: 767px) {
	h2 {
		font-size: 30px;
	}
}

@media screen and (max-width: 575px) {
	h2 {
		line-height: 34px;
	}
}

.section-title {
	margin-bottom: 40px;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.section-title h2 {
	margin-bottom: 0;
}

.btn {
	font-family: "Prompt", sans-serif;
	font-size: 16px;
	line-height: 24px;
	font-weight: 500;
	padding: 7px 16px;
	border-radius: 8px;
	border: none;
	outline: none;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-transition: all 300ms ease-in-out;
	transition: all 300ms ease-in-out;
}

.btn:focus {
	-webkit-box-shadow: none;
	box-shadow: none;
	outline: none;
}

.btn-outline {
	    background-color: #ffeaea;
	    color: #fe2f2f;
	    border: 1px solid #ffacac;
}

.btn-outline:focus,
.btn-outline:hover {
	background: #fe2f2f;
	color: #fff;
	-webkit-box-shadow: none;
	box-shadow: none;
	border-color: #fe2f2f;
}

.swiper__pagination .swiper-button-next,
.swiper__pagination .swiper-button-prev {
	top: auto;
	bottom: 0;
	left: 18px;
}

.swiper__pagination .swiper-button-next::after,
.swiper__pagination .swiper-button-prev::after {
	display: none;
}

.swiper__pagination .swiper-button-next svg path,
.swiper__pagination .swiper-button-prev svg path {
	stroke: #252522;
}

.swiper__pagination .swiper-button-next {
	margin-left: 18px;
}

.swiper__pagination .swiper-button-prev {
	margin-left: -18px;
}

.swiper__pagination .swiper-pagination-progressbar {
	top: auto;
	bottom: 20px;
	width: 95%;
	left: 89px;
	height: 1px;
	background: #eaecf0;
}

.swiper__pagination .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
	background: #667085;
}

.product-card {
	margin-bottom: 60px;
}

.product-card .product__image__wrapper {
	position: relative;
	width: 100%;
	aspect-ratio: 1/1;
	background: #f8f8f8;
	border-radius: 16px;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.product-card .product__image__wrapper .product__image {
	position: relative;
	height: 100%;
	width: 100%;
	border-radius: 20px;
	overflow: hidden;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
}
.product-card .product__image__wrapper{
  
    background: #f8f8f800;
   
}
.product-card .product__image__wrapper .product__image img {
	width: 100%;
	height: 100%;
	-o-object-fit: contain;
	object-fit: contain;
	-o-object-position: center;
	object-position: center;
	display: block;
	border-radius: 20px;
	-webkit-transition: all 300ms ease-in-out;
	transition: all 300ms ease-in-out;
}

.product-card .product__image__wrapper .product__actions {
	position: absolute;
	right: 15px;
	top: 15px;
}

.product-card .product__image__wrapper .product__actions .action__btn {
	height: 30px;
	width: 30px;
	color: #403c39;
	font-size: 14px;
	border-radius: 4px;
	background: #fff;
	border: 1px solid #dddddd;
	margin-bottom: 8px;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	position: absolute;
	right: 0;
	opacity: 0;
	visibility: hidden;
	-webkit-transition: all 300ms ease-in-out;
	transition: all 300ms ease-in-out;
}

.product-card .product__image__wrapper .product__actions .action__btn svg {
	margin-right: 0;
}

.product-card .product__image__wrapper .product__actions .action__btn:nth-of-type(1) {
	z-index: 1;
	top: -7px;
}

.product-card .product__image__wrapper .product__actions .action__btn:nth-of-type(2) {
	top: 0px;
}

.product-card .product__image__wrapper .product__actions .action__btn:nth-of-type(3) {
	top: 0px;
}

.product-card .product__image__wrapper .product__actions .action__btn:last-of-type {
	margin-bottom: 0;
}

.product-card .product__image__wrapper .product__actions .action__btn:hover {
	border-color: #a7a7a7;
}

.product-card .product__image__wrapper .badge {
	font-family: "Prompt", sans-serif;
	font-size: 13px;
	font-weight: 500;
	line-height: 16px;
	color: #fff;
	padding: 2px 6px;
	border-radius: 6px;
	background: #fe2f2f;
	min-width: 38px;
	position: absolute;
	top: 15px;
	left: 15px;
}

.product-card .product__content {
	margin-top: 16px;
}

.product-card .product__content .product__rating {
	-webkit-box-align: baseline;
	-ms-flex-align: baseline;
	align-items: baseline;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-pack: inherit;
	-ms-flex-pack: inherit;
	justify-content: inherit;
}

.product-card .product__content .product__rating ul {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-pack: inherit;
	-ms-flex-pack: inherit;
	justify-content: inherit;
	list-style: none;
}

.product-card .product__content .product__rating ul li {
	font-size: 11px;
	color: #ffd600;
	height: 14px;
	width: 14px;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.product-card .product__content .product__title {
	margin-top: 2px;
	margin-bottom: 16px;
}

.product-card .product__content .product__title h5 {
	font-family: "Prompt", sans-serif;
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
	color: #252522;
	margin-bottom: 0;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
}

@media screen and (max-width: 1440px) {
	.product-card .product__content .product__title h5 {
		font-size: 14px;
		line-height: 22px;
	}
}

.product-card .product__content .product__title h5 a {
	color: #252522;
	display: block;
	-webkit-transition: all 300ms ease-in-out;
	transition: all 300ms ease-in-out;
}

.product-card .product__content .product__bottom {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
}

.product-card .product__content .product__price {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-pack: inherit;
	-ms-flex-pack: inherit;
	justify-content: inherit;
	font-family: "Prompt", sans-serif;
	font-size: 16px;
	font-weight: 800;
	line-height: 20px;
	color: #403c39;
}

@media screen and (max-width: 1440px) {
	.product-card .product__content .product__price {
		font-size: 13px;
	}
}

.product-card .product__content .product__price del {
	font-size: 12px;
	font-weight: 400;
	line-height: 20px;
	margin-left: 6px;
	display: block;
	color: #98a2b3;
}

@media screen and (max-width: 1440px) {
	.product-card .product__content .product__price del {
		font-size: 11px;
	}
}

.product-card .product__content .total__rating {
	font-family: "Prompt", sans-serif;
	font-size: 10px;
	font-weight: 400;
	line-height: 20px;
	color: #98a2b3;
	margin-left: 2px;
	display: inline-block;
}

.product-card:hover .product__image img {
	-webkit-transform: scale(1.1);
	transform: scale(1.1);
}

.product-card:hover .product__title h5 a {
	color: #fe2f2f;
}

.product-card:hover .product__actions .action__btn {
	opacity: 1;
	visibility: visible;
}

.product-card:hover .product__actions .action__btn:nth-of-type(1) {
	top: 0px;
}

.product-card:hover .product__actions .action__btn:nth-of-type(2) {
	top: 38px;
}

.product-card:hover .product__actions .action__btn:nth-of-type(3) {
	top: 76px;
}

.cart__action__btn {
	position: relative;
}

.cart__action__btn .cart__btn .btn {
	width: 120px;
	height: 40px;
}

@media screen and (max-width: 1440px) {
	.cart__action__btn .cart__btn .btn {
		padding: 7px;
		width: 100px;
		font-size: 14px;
	}
}

.cart__action__btn .cart__quantity {
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	display: none;
}

@media screen and (max-width: 1440px) {
	.cart__action__btn .cart__quantity {
		width: 100px;
	}
}

.quantity {
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	border-radius: 8px;
	padding: 10px;
	width: 120px;
	height: 40px;
	background: #a7a7a7;
	color: #fff;
}

.quantity > input[type="number"] {
	border: none;
	font-size: 15px;
	margin: 0;
	text-align: center;
	-webkit-box-flex: 1;
	-ms-flex: 1;
	flex: 1;
	width: 100%;
	color: currentColor;
	background: transparent;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	outline: none;
}

.quantity .incressQnt,
.quantity .decressQnt {
	cursor: pointer;
	background: transparent;
	height: 15px;
	width: 15px;
	border: none;
	outline: none;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	position: relative;
	color: currentColor;
}

.quantity .incressQnt .bar,
.quantity .decressQnt .bar {
	position: absolute;
	top: 50%;
	height: 2px;
	width: 15px;
	background: currentColor;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	right: 0px;
	background-image: none;
	border-radius: 8px;
}

@media screen and (max-width: 1440px) {
	.quantity .incressQnt .bar,
	.quantity .decressQnt .bar {
		width: 12px;
	}
}

.quantity .incressQnt .bar::before,
.quantity .decressQnt .bar::before {
	content: "";
	position: absolute;
	top: 50%;
	height: 15px;
	width: 2px;
	background: currentColor;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	right: 6px;
	background-image: none;
	border-radius: 8px;
	-webkit-transition: all 300ms ease-in-out;
	transition: all 300ms ease-in-out;
}

@media screen and (max-width: 1440px) {
	.quantity .incressQnt .bar::before,
	.quantity .decressQnt .bar::before {
		height: 12px;
		right: 5px;
	}
}

.quantity .decressQnt .bar::before {
	display: none;
}

.action__btn {
	font-family: "Prompt", sans-serif;
	font-size: 16px;
	font-weight: 500;
	line-height: 20px;
	color: #667085;
	border: none;
	outline: none;
	background: transparent;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
	width: 100%;
	-webkit-transition: all 300ms ease-in-out;
	transition: all 300ms ease-in-out;
}

.action__btn svg {
	margin-right: 6px;
	-webkit-transition: all 300ms ease-in-out;
	transition: all 300ms ease-in-out;
}

.action__btn:focus {
	border: none;
	outline: none;
}

.action__btn:hover {
	color: #a7a7a7;
}

.action__btn:hover svg path {
	stroke: #a7a7a7;
}

@media print {
	* {
		color: #000000!important;
	}
}

.swiper-wrapper{
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    transition-property: transform;
    box-sizing: content-box;
    flex-wrap: wrap;
    flex-direction: row;
}.row {
   
    margin-left: -34px;
}.product-card .product__content .product__title h5 a {
    
    font-family: 'Prompt';
}.product-card .product__content .product__price {
  
    font-family: 'Prompt';
    font-weight: 800;
}.cart__action__btn .cart__btn .btn {
  
    font-family: 'Prompt';
    font-weight: 800;
}.product-card .product__content .product__title{
   
    max-height: 50px;
    min-height: 50px;
 
}.section-title {
    margin-bottom: 40px;
    align-items: center;
    margin-top: 40px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}.swiper-slide {
    flex-shrink: 0;
    width: 100%;
    width: 180px;
    height: 100%;
    width: 190px !important;
	margin-right: 80px !important;
    position: relative;
    transition-property: transform;
}.product-card .product__content .product__title h5 {
    font-family: "Prompt", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    color: #252522;
    margin-bottom: 0;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}.cart__action__btn .cart__btn .btn {
    width: 83px;
    height: 38px;
    font-size: 13px;
    border: 2px solid #ffacac;
}.product-card .product__content .product__price{
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: inherit;
    font-family: "Prompt", sans-serif;
    font-size: 17px;
    font-weight: 800;
    line-height: 20px;
    color: #403c39;
}
</style>
